import { graphql } from "gatsby"
import React, { FC } from "react"
import { Props } from "./types"
import * as styles from "./styles.module.scss"
import SEO from "src/components/SEO"
import Google from "src/images/google-logo.png"
import Microsoft from "src/images/microsoft-logo.png"
import { useGoogleAuth } from "src/hooks/useGoogleAuth"
import { useMicrosoftAuth } from "src/hooks/useMicrosoftAuth"
import ArrowLink from "src/components/ArrowLink"
import Link from "src/utils/Link"
import { usePages } from "src/context/pages"
import HideDraft from "src/components/HideDraft"
import { useSignUp } from "src/hooks/useSignUp"
import Logo from "src/images/structured-data-logo.png"
import LogoSvg from "src/components/Icons/Logo"
import Img from "gatsby-image"

const Template: FC<Props> = ({
  data: {
    file: { childYaml: texts },
  },
}) => {
  const signUp = useSignUp(texts)
  const { googleSignIn } = useGoogleAuth()
  const { signInWithMicrosoft } = useMicrosoftAuth()
  const { getPage, currentPath } = usePages()

  async function onMicrosoftClick() {
    signInWithMicrosoft()
  }

  async function onGoogleClick() {
    googleSignIn()
  }

  return (
    <>
      <HideDraft />
      <SEO
        title={texts.metaTitle}
        description={texts.metaDescription}
        openGraph={{
          title: texts.metaTitle,
          description: texts.metaDescription,
          url: `${process.env.GATSBY_CANONICAL_URL}${currentPath}`,
          images: [
            {
              url: `${process.env.GATSBY_CANONICAL_URL}${Logo}`,
              width: 800,
              height: 600,
              alt: "Flow logo",
            },
            {
              url: `${process.env.GATSBY_CANONICAL_URL}${Logo}`,
              width: 900,
              height: 800,
              alt: "Flow logo",
            },
          ],
        }}
      />
      <main className={styles.main}>
        <Link to="/">
          <LogoSvg className={styles.logo} />
        </Link>
        <div>
          <div className={styles.container}>
            <div className={styles.icon}>
              <Img
                fluid={texts.icon.childImageSharp.fluid}
                style={{ height: "100%", width: "100%" }}
              />
            </div>
            <h1 className={styles.title}>{texts.createAccount}</h1>
            <div className={styles.offerDescription}>
              <span>{texts.freeFor30Days}</span>
              <span>{texts.creditCard}</span>
              <span>{texts.cancel}</span>
            </div>
            <div className={`${styles.separatorText} ${styles.error}`}>
              {signUp.status === "error" ? signUp.error : ""}
            </div>
            <form
              className={`${styles.contentInput} ${signUp.status === "error" &&
                styles.error}`}
              {...signUp.formProps}
            >
              <input
                className={styles.emailInput}
                type="text"
                placeholder={texts.placeholderEmail}
                {...signUp.inputProps}
                autoFocus={true}
              />
              <button
                className={`button button-second ${styles.emailButton}`}
                type="submit"
                {...signUp.buttonProps}
              >
                {texts.signUp}
              </button>
            </form>
            <div className={`${styles.separatorText} `}>{texts.orEmail}</div>
            <button
              className={`button button-second ${styles.socialSignInContainer}`}
              onClick={onGoogleClick}
            >
              <div aria-hidden="true" className={styles.socialSignInIcon}>
                <img src={Google} className={styles.img} />
              </div>
              <span className={styles.socialSignInLabel}>{texts.google}</span>
            </button>
            <button
              className={`button button-second ${styles.socialSignInContainer}`}
              onClick={onMicrosoftClick}
            >
              <div aria-hidden="true" className={styles.socialSignInIcon}>
                <img src={Microsoft} className={styles.imgMicrosoft} />
              </div>
              <span className={styles.socialSignInLabel}>
                {texts.microsoft}
              </span>
            </button>
            <div className={styles.footer}>
              <p className={styles.legalText}>
                {texts.agree}
                <Link
                  to={getPage("legal/terms-of-service").url}
                  className={styles.linkLegal}
                >
                  {texts.TermsOfService}
                </Link>
                <span> {texts.and} </span>
                <Link
                  to={getPage("legal/privacy-policy").url}
                  className={styles.linkLegal}
                >
                  {texts.PrivacyPolicy}
                </Link>
                .
              </p>
            </div>
          </div>
          <div className={styles.logosContent}>
            <Img
              fluid={texts.icons.childImageSharp.fluid}
              placeholderStyle={{ visibility: "hidden" }}
            />
            <span className={styles.logosText}>{texts.logosText}</span>
          </div>
        </div>

        <ArrowLink
          to={"https://app.getflow.com"}
          text={texts.logIn}
          className={styles.link}
        />
      </main>
    </>
  )
}

export const pageQuery = graphql`
  query($ymlPath: String!) {
    file(absolutePath: { eq: $ymlPath }) {
      childYaml {
        metaTitle
        metaDescription
        freeFor30Days
        google
        microsoft
        orEmail
        placeholderEmail
        createAccount
        creditCard
        cancel
        agree
        logIn
        invalidEmailError
        unknownSignUpError
        signUp
        TermsOfService
        PrivacyPolicy
        and
        icon {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        icons {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        logosText
      }
    }
  }
`

export default Template
